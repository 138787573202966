<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备名称" prop="devicename">
              <a-input v-model.trim="queryParams.devicename" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="注册代码" prop="devicenum">
              <a-input v-model.trim="queryParams.devicenum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备类型" prop="terminaltype">
              <a-select v-model="queryParams.terminaltype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="电梯救援码" prop="liftrescue">
              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯注册码" prop="liftnum">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="电梯地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="设备组名称" prop="adress">
              <a-input v-model.trim="queryParams.deviceGroupname" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">设备列表</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="deviceinfoid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="terminaltype" slot-scope="text">
            <span class="text">{{terminaltypesMap[text]||''}}</span>
          </span>
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="showModal(value, record)">配置</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>
<script>
import pagination from '@/mixins/pagination'
import {getCache, getItemFromArrayByKey} from 'U'
import {
  getTerminaltypeListByCondition,
  getDeviceinfoListByCondition,
  getDevicegroupdevListByCondition, setDeviceparamValueList, addRecoverLog
} from 'A/ai.js'
import {getDevicegroupListByCondition} from "A/ai";
import moment from "moment";
export default {
  name:'advanceparam',
  mixins: [pagination],
  data() {
    return {
      showAdvanced: false,
      terminaltypes: [],
      queryParams: {
        devicename: '',
        devicenum: '',
        terminaltype: '',
        liftrescue: '',
        liftnum: '',
        adress: '',
        deviceGroupname:'',
        devicegroupid:'',
      },
      tableColumns: [
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '设备型号',
          dataIndex: 'terminaltype',
          key: 'terminaltype',
          ellipsis: true,
          scopedSlots: { customRender: 'terminaltype' }
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
        // {
        //   title: '电梯救援码',
        //   dataIndex: 'liftrescue',
        //   key: 'liftrescue',
        //   ellipsis: true,
        // },
        // {
        //   title: '电梯注册码',
        //   dataIndex: 'liftnum',
        //   key: 'liftnum',
        //   ellipsis: true,
        // },
        // {
        //   title: '电梯地址',
        //   dataIndex: 'adress',
        //   key: 'adress',
        //   ellipsis: true,
        // },
        {
          title: '参数配置',
          key: 'operation',
          width: 70,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      updateModalVisible: false,
      devicenum: '',
      devicegroupid:'',
      username:'',
    }
  },
  computed: {
    terminaltypesMap() {
      let result = {};
      this.terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    },
    pageTitle() {
      return '终端高级参数';
    },
    breadcrumb() {
      return [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '高级设置',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  created() {
    this.getTerminaltypes();
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.username = logininfo.usernames;
    }
  },
  methods: {
    getTerminaltypes() {
      getTerminaltypeListByCondition({}).then(res => {
        if(res && res.returncode == '0') {
          this.terminaltypes = res.item.map(item => {
            return {
              value: item.terminaltypeid,
              text: item.terminaltypename,
            }
          });
        }
        this.getTableData();
      }).catch(err => {
        this.getTableData();
      })
    },
    resetQueryParams() {
      this.queryParams.deviceGroupname='';
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getDeviceinfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    showModal(value,record){
      this.$router.push({
        name: 'paramsConfig',
        params: {
          prePages: [
            {
              name: '网络管理',
              path: ''
            },
            {
              name: '监控硬终端',
              path: ''
            },
            {
              name: '设备列表',
              path: 'enquipmentList'
            },
          ],
          devicenum: record.devicenum,
          liftid: record.liftid,
          paramFlag:{
            mqttFlag:1,
            iotFlag:1,
            videoAiFlag:1,
            linphoneFlag:1,
            displayFlag:1,
            selfTestFlag:1,
            videoParamFlag:1,
          }
        }
      })
    },
  }
}
</script>